import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { STORE_OR_NETWORK, useQuery } from 'relay-hooks';
import { Dimmer, Dropdown, Loader, Segment } from 'semantic-ui-react';

import '../assets/css/BookList.css';
import bookData from '../assets/data/books-page.data';
import blackBannerData from '../assets/data/blackBannerData';

import vector1 from '../assets/images/Vector.png';
import vector2 from '../assets/images/Union.png';
import SearchIcon from '../assets/images/search.png';

import MainScreen from '../common/MainScreen';
import BlackBanner from '../common/BlackBanner';

import Books from '../common/booklist/Books';

import BOOKS_QUERY, {
  allBooksQuery,
} from '../__generated__/allBooksQuery.graphql';
import ALLSUBJECTS_QUERY, {
  allSubjectsQuery,
} from '../__generated__/allSubjectsQuery.graphql';
import BooksPage from './BooksPage';

const BookList: FunctionComponent = () => {
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = useState<any>('');
  const [subjects, setSubjects] = useState<string | null>('');
  const [newestoldest, setNewestoldest] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingtext, setLoadingtext] = useState<boolean>(false);

  const allSubject = useQuery<allSubjectsQuery>(ALLSUBJECTS_QUERY, {
    fetchPolicy: STORE_OR_NETWORK,
  });
  const allBooks = useQuery<allBooksQuery>(
    BOOKS_QUERY,
    { isShown: true },
    { fetchPolicy: STORE_OR_NETWORK }
  );

  const allBooksArray =
    allBooks &&
    allBooks?.data?.allBooks?.edges.map((item: any) => {
      if (item.node.isShown === false) {
        return item.node;
      } else null;
    });

  const allSubjectArray =
    allSubject &&
    allSubject.data &&
    allSubject?.data?.allSubjects &&
    allSubject?.data?.allSubjects?.edges.map((item: any) => {
      return item.node;
    });

  const [subjectsOptions, setsubjectsOptions] = useState<any>([
    {
      key: 'Hepsi',
      value: 'Hepsi',
      text: 'Hepsi',
    },
  ]);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const formatItem = (item: any) => {
    return {
      key: item.name,
      value: item.name,
      text: item.name,
    };
  };

  useEffect(() => {
    const tempArr = allSubjectArray?.map((item: string) => {
      return formatItem(item);
    });

    if (allBooks.error) {
      setLoading(true);
      setLoadingtext(true);
    } else {
      setLoading(false);
      setLoadingtext(false);

      if (
        allBooks?.data?.allBooks &&
        allBooks?.data?.allBooks &&
        allBooks?.data?.allBooks?.edges &&
        allBooks?.data?.allBooks?.edges?.length > 0
      ) {
        setLoading(false);
      } else {
        setLoading(true);
      }
    }

    setsubjectsOptions(tempArr);
    if (subjects?.length === 0) {
      setsubjectsOptions(tempArr);
    } else if (subjects && subjects?.length > 0) {
      if (tempArr && tempArr.length > 0) {
        setsubjectsOptions([
          {
            key: 'Hepsi',
            value: 'Hepsi',
            text: 'Hepsi',
          },
          // eslint-disable
          ...tempArr,
        ]);
      }
    }
  }, [allSubject, subjects, allBooks]);
  const booksMemo = useMemo(() => {
    const renderList = allBooksArray?.filter(filter => {
      return (
        filter?.name.toLowerCase().includes(search.toLowerCase()) ||
        filter?.authors?.edges[0]?.node?.name
          .toLowerCase()
          .includes(search.toLowerCase())
      );
    });
    if (newestoldest === 'En Eski') {
      renderList?.reverse();
    }
    if (newestoldest === 'En Çok İndirilen') {
      renderList?.sort(function(a, b) {
        return b.downloadCount - a.downloadCount;
      });
    }
    if (subjects === 'Hepsi') {
      return renderList;
    }
    if (subjects) {
      return renderList?.reduce(function(obj, item) {
        item?.subjects?.edges?.forEach((newItem: any) => {
          if (newItem?.node?.name === subjects) {
            obj.push(item);
          }
        });
        return obj;
      }, []);
    }
    return renderList;
  }, [subjects, allBooksArray, newestoldest, search]);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const onchange = (e: any) => {
    setSearch(e.target.value);
  };
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const ontimes = (e: any) => {
    setNewestoldest(e.target.innerText);
  };
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const onChangeSubjects = (e: any) => {
    setSubjects(e.target.innerText);
  };

  const options = [
    {
      key: 'true',
      text: 'En Yeni',
      value: 'newest',
    },
    {
      key: 'true2',
      text: 'En Eski',
      value: 'oldest',
    },
    {
      key: 'true3',
      text: 'En Çok İndirilen',
      value: 'mostdownload',
    },
  ];

  return (
    <div className="App">
      <title>Kitap Dinler Kitaplarımız</title>
      <meta
        property="og:description"
        data-rh="true"
        content="Kitap Dinler,Sesli Kitap, Kitap Dinle, Sesli Kitap İndir ,Dinle,"
      />
      <link href="/kitaplistesi" rel="stylesheet"></link>
      <MainScreen data={bookData[0]} />
      <BlackBanner data={blackBannerData[0]} />

      {loading ? (
        <div>
          <Segment
            className="bg"
            style={{
              background:
                'linear-gradient( 180deg, #040F31 0%, #6d4753 24.37%, #905a81 41.39%, #502d80 58.65%, #1c1b60 75.83%, #0b1552 92.5%)',
              height: '70vh',
            }}
          >
            <Dimmer className="Dimmer" active>
              <div className="loading-component">
                <Loader className="loading" size="massive" />
              </div>
              {loadingtext ? (
                <div className="loading-text">Bu Sayfa Bakıma alınmıştır</div>
              ) : (
                <div className="loading-text">Yükleniyor</div>
              )}
            </Dimmer>
            <img src={vector1} alt="logo" className="bg-image" />
            <img src={vector2} alt="logo" className="bg-image2" />
          </Segment>
        </div>
      ) : (
        <div
          className="bg"
          style={{
            background:
              'linear-gradient( 180deg, #040F31 0%, #6d4753 24.37%, #905a81 41.39%, #502d80 58.65%, #1c1b60 75.83%, #0b1552 92.5%)',
          }}
        >
          <div className="booklist">
            <div className="booklist-filter-container">
              <div className="booklist-filter-wrapper">
                <h1 className="booklist-title">KİTAPLARIMIZ</h1>
                <div className="booklist-dropdown">
                  <div className="dropdown">
                    <Dropdown
                      placeholder="Konular"
                      icon="chevron up"
                      selection
                      className="dropbtn"
                      multiple={false}
                      options={subjectsOptions}
                      onChange={onChangeSubjects}
                    />
                  </div>
                  <div className="dropdown">
                    <Dropdown
                      placeholder="Sıralama"
                      icon="chevron up"
                      selection
                      className="dropbtn"
                      multiple={false}
                      options={options}
                      onChange={ontimes}
                      defaultValue={options[0].value}
                    />
                  </div>
                  <input
                    placeholder="Kitap ve Yazar Arayın"
                    className="dropbtnaratext"
                    type="text"
                    id="fname"
                    name="fname"
                    onChange={onchange}
                  />
                  <button className="dropbtnara">Ara</button>
                  <button
                    className="dropbtn_mobilicon"
                    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                    onClick={() => {
                      open ? setOpen(false) : setOpen(true);
                    }}
                  >
                    <img className="dropimg1" src={SearchIcon} />{' '}
                  </button>{' '}
                </div>
                {open ? (
                  <input
                    placeholder="Kitap ve Yazar Arayın"
                    className="dropbtnaratext-mobil"
                    type="text"
                    id="fname"
                    name="fname"
                    onChange={onchange}
                  />
                ) : null}
              </div>
            </div>
          </div>

          <div className="bize-ayrilan-bolge">
            <img src={vector1} alt="logo" className="bg-image" />
            {booksMemo <= 0 ? (
              <div className="sonuc">
                <h2 className="sonuc-title">
                  Aradığınız kitap bulunamamıştır...
                </h2>
              </div>
            ) : null}
            <Books data={booksMemo} authors={false} />
            <img src={vector2} alt="logo" className="bg-image2" />
          </div>
        </div>
      )}
      <BooksPage />
    </div>
  );
};

export default BookList;
