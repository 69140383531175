/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type allAuthorsQueryVariables = {};
export type allAuthorsQueryResponse = {
    readonly allAuthors: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly createdAt: unknown | null;
                readonly name: string;
                readonly description: string;
                readonly bookAuthors: {
                    readonly edges: ReadonlyArray<{
                        readonly node: {
                            readonly id: string;
                            readonly name: string;
                            readonly authors: {
                                readonly edges: ReadonlyArray<{
                                    readonly node: {
                                        readonly id: string;
                                        readonly name: string;
                                    } | null;
                                } | null>;
                            };
                        } | null;
                    } | null>;
                };
                readonly coverPhoto: {
                    readonly images: {
                        readonly edges: ReadonlyArray<{
                            readonly node: {
                                readonly externalUrl: string | null;
                                readonly file: {
                                    readonly url: string | null;
                                } | null;
                            } | null;
                        } | null>;
                    };
                } | null;
            } | null;
        } | null>;
    } | null;
};
export type allAuthorsQuery = {
    readonly response: allAuthorsQueryResponse;
    readonly variables: allAuthorsQueryVariables;
};



/*
query allAuthorsQuery {
  allAuthors {
    edges {
      node {
        createdAt
        name
        description
        bookAuthors {
          edges {
            node {
              id
              name
              authors {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }
        coverPhoto {
          images {
            edges {
              node {
                externalUrl
                file {
                  url
                }
                id
              }
            }
          }
          id
        }
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "BookNodeConnection",
  "kind": "LinkedField",
  "name": "bookAuthors",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BookNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BookNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "AuthorNodeConnection",
              "kind": "LinkedField",
              "name": "authors",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AuthorNodeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AuthorNode",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v3/*: any*/),
                        (v1/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "externalUrl",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "ImageFileType",
  "kind": "LinkedField",
  "name": "file",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "allAuthorsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthorNodeConnection",
        "kind": "LinkedField",
        "name": "allAuthors",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthorNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AuthorNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ImageGroupNode",
                    "kind": "LinkedField",
                    "name": "coverPhoto",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ImageNodeConnection",
                        "kind": "LinkedField",
                        "name": "images",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ImageNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ImageNode",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  (v6/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "allAuthorsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthorNodeConnection",
        "kind": "LinkedField",
        "name": "allAuthors",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthorNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AuthorNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ImageGroupNode",
                    "kind": "LinkedField",
                    "name": "coverPhoto",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ImageNodeConnection",
                        "kind": "LinkedField",
                        "name": "images",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ImageNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ImageNode",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  (v6/*: any*/),
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "allAuthorsQuery",
    "operationKind": "query",
    "text": "query allAuthorsQuery {\n  allAuthors {\n    edges {\n      node {\n        createdAt\n        name\n        description\n        bookAuthors {\n          edges {\n            node {\n              id\n              name\n              authors {\n                edges {\n                  node {\n                    id\n                    name\n                  }\n                }\n              }\n            }\n          }\n        }\n        coverPhoto {\n          images {\n            edges {\n              node {\n                externalUrl\n                file {\n                  url\n                }\n                id\n              }\n            }\n          }\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '8254000ffbc31fe71a3f40c61470c1ff';
export default node;
