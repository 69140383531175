/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type allBooksQueryVariables = {
    isShown?: boolean | null;
};
export type allBooksQueryResponse = {
    readonly allBooks: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly createdAt: unknown | null;
                readonly name: string;
                readonly isShown: boolean;
                readonly description: string;
                readonly downloadCount: number;
                readonly duration: string | null;
                readonly coverPhoto: {
                    readonly images: {
                        readonly edges: ReadonlyArray<{
                            readonly node: {
                                readonly externalUrl: string | null;
                                readonly file: {
                                    readonly url: string | null;
                                } | null;
                            } | null;
                        } | null>;
                    };
                } | null;
                readonly authors: {
                    readonly edges: ReadonlyArray<{
                        readonly node: {
                            readonly id: string;
                            readonly name: string;
                        } | null;
                    } | null>;
                };
                readonly subjects: {
                    readonly edges: ReadonlyArray<{
                        readonly node: {
                            readonly id: string;
                            readonly name: string;
                        } | null;
                    } | null>;
                };
            } | null;
        } | null>;
    } | null;
};
export type allBooksQuery = {
    readonly response: allBooksQueryResponse;
    readonly variables: allBooksQueryVariables;
};



/*
query allBooksQuery(
  $isShown: Boolean
) {
  allBooks(createdDate: "", isShown: $isShown) {
    edges {
      node {
        createdAt
        name
        isShown
        description
        downloadCount
        duration
        coverPhoto {
          images {
            edges {
              node {
                externalUrl
                file {
                  url
                }
                id
              }
            }
          }
          id
        }
        authors {
          edges {
            node {
              id
              name
            }
          }
        }
        subjects {
          edges {
            node {
              id
              name
            }
          }
        }
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isShown",
    "type": "Boolean"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "createdDate",
    "value": ""
  },
  {
    "kind": "Variable",
    "name": "isShown",
    "variableName": "isShown"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isShown",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "downloadCount",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "duration",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "externalUrl",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "ImageFileType",
  "kind": "LinkedField",
  "name": "file",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = [
  (v10/*: any*/),
  (v3/*: any*/)
],
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "AuthorNodeConnection",
  "kind": "LinkedField",
  "name": "authors",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AuthorNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AuthorNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v11/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "BookSubjectNodeConnection",
  "kind": "LinkedField",
  "name": "subjects",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BookSubjectNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BookSubjectNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v11/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "allBooksQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BookNodeConnection",
        "kind": "LinkedField",
        "name": "allBooks",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BookNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BookNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ImageGroupNode",
                    "kind": "LinkedField",
                    "name": "coverPhoto",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ImageNodeConnection",
                        "kind": "LinkedField",
                        "name": "images",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ImageNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ImageNode",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v8/*: any*/),
                                  (v9/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v12/*: any*/),
                  (v13/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "allBooksQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BookNodeConnection",
        "kind": "LinkedField",
        "name": "allBooks",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BookNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BookNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ImageGroupNode",
                    "kind": "LinkedField",
                    "name": "coverPhoto",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ImageNodeConnection",
                        "kind": "LinkedField",
                        "name": "images",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ImageNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ImageNode",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v8/*: any*/),
                                  (v9/*: any*/),
                                  (v10/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "allBooksQuery",
    "operationKind": "query",
    "text": "query allBooksQuery(\n  $isShown: Boolean\n) {\n  allBooks(createdDate: \"\", isShown: $isShown) {\n    edges {\n      node {\n        createdAt\n        name\n        isShown\n        description\n        downloadCount\n        duration\n        coverPhoto {\n          images {\n            edges {\n              node {\n                externalUrl\n                file {\n                  url\n                }\n                id\n              }\n            }\n          }\n          id\n        }\n        authors {\n          edges {\n            node {\n              id\n              name\n            }\n          }\n        }\n        subjects {\n          edges {\n            node {\n              id\n              name\n            }\n          }\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'a8f94a7335d14bb57d9ce2c7a6698910';
export default node;
