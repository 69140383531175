import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { STORE_OR_NETWORK, useQuery } from 'relay-hooks';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';

import '../assets/css/AuthorList.css';
import bookData from '../assets/data/books-page.data';
import blackBannerData from '../assets/data/blackBannerData';
import vector1 from '../assets/images/Vector.png';
import vector2 from '../assets/images/Union.png';

import MainScreen from '../common/MainScreen';
import BlackBanner from '../common/BlackBanner';
import Author from '../common/authorlist/Author';

import BannerComponent from '../common/Banner';
import FooterComponent from '../common/Footer';
import bannerData from '../assets/data/banner-data';
import AUTHOR_QUERY, {
  allAuthorsQuery,
} from '../__generated__/allAuthorsQuery.graphql';

const AuthorList: FunctionComponent = () => {
  const [newestoldest, setNewestoldest] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingtext, setLoadingtext] = useState<boolean>();

  const allAuthor = useQuery<allAuthorsQuery>(AUTHOR_QUERY, {
    fetchPolicy: STORE_OR_NETWORK,
  });

  const allAuthorsArray =
    allAuthor &&
    allAuthor?.data?.allAuthors?.edges.map((item: any) => {
      return item.node;
    });

  useEffect(() => {
    if (allAuthor.error) {
      setLoading(true);
      setLoadingtext(true);
    } else {
      setLoading(false);
      setLoadingtext(false);

      if (
        allAuthor?.data?.allAuthors &&
        allAuthor?.data?.allAuthors &&
        allAuthor?.data?.allAuthors?.edges &&
        allAuthor?.data?.allAuthors?.edges?.length > 0
      ) {
        setLoading(false);
      } else {
        setLoading(true);
      }
    }
  }, [allAuthor]);

  const AuthorData = useMemo(() => {
    const renderList = allAuthorsArray?.filter(filter => {
      return filter?.name;
    });

    if (newestoldest === 'En Eski') {
      renderList?.reverse();
    }

    if (newestoldest === 'Z-A') {
      renderList?.sort(function(a, b) {
        return a.name.localeCompare(b.name);
      });
    }
    if (newestoldest === 'A-Z') {
      renderList?.sort(function(a, b) {
        return b?.name.localeCompare(a.name);
      });
    }

    return renderList;
  }, [allAuthorsArray, newestoldest]);
  return (
    <div className="App">
      <title>Kitap Dinler Yazarlarımız</title>
      <meta
        property="og:description"
        data-rh="true"
        content="Kitap Dinler,Sesli Kitap, Kitap Dinle, Sesli Kitap İndir ,Dinle,"
      />
      <link href="/yazarlarimiz" rel="stylesheet"></link>
      <MainScreen data={bookData[1]} />
      <BlackBanner data={blackBannerData[0]} />
      {loading ? (
        <div>
          <Segment
            className="bg"
            style={{
              background:
                'linear-gradient( 180deg, #040F31 0%, #6d4753 24.37%, #905a81 41.39%, #502d80 58.65%, #1c1b60 75.83%, #0b1552 92.5%)',
              height: '70vh',
            }}
          >
            <Dimmer className="Dimmer" active>
              <div className="loading-component">
                <Loader className="loading" size="massive" />
              </div>
              {loadingtext ? (
                <div className="loading-text">Bu Sayfa Bakıma alınmıştır</div>
              ) : (
                <div className="loading-text">Yükleniyor</div>
              )}
            </Dimmer>
            <img src={vector1} alt="logo" className="bg-image" />
            <img src={vector2} alt="logo" className="bg-image2" />
          </Segment>
        </div>
      ) : (
        <div
          className="bg"
          style={{
            background:
              'linear-gradient( 180deg, #040F31 0%, #6d4753 24.37%, #905a81 41.39%, #502d80 58.65%, #1c1b60 75.83%, #0b1552 92.5%)',
          }}
        >
          <div className="authorlist">
            <div className="authorlist-filter-container">
              <div className="authorlist-filter-wrapper">
                <h1 className="authorlist-title">Yazarlarımız</h1>
                <div className="authorlist-dropdown">
                  {newestoldest == 'A-Z' ? (
                    <div
                      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                      onClick={() => setNewestoldest('Z-A')}
                      className="dropdown"
                    >
                      Z-A
                    </div>
                  ) : (
                    <div
                      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                      onClick={() => setNewestoldest('A-Z')}
                      className="dropdown"
                    >
                      A-Z
                    </div>
                  )}

                  {newestoldest == 'En Eski' ? (
                    <div
                      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                      onClick={() => setNewestoldest('En Yeni')}
                      className="dropdown"
                    >
                      Yeniden Eskiye
                    </div>
                  ) : (
                    <div
                      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                      onClick={() => setNewestoldest('En Eski')}
                      className="dropdown"
                    >
                      Eskiden Yeniye
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="bize-ayrilan-bolge">
            <Author data={AuthorData} />
            <img src={vector2} alt="logo" className="bg-image2" />
          </div>
        </div>
      )}

      <BannerComponent data={bannerData[0]} />
      <FooterComponent />
    </div>
  );
};

export default AuthorList;
